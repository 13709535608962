import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { Box, Heading, Text } from "@chakra-ui/react";

import Layout from "../components/Layout";
import Container from "../components/Container";
import Content, { HTMLContent } from "../components/Content";

export const CoursePostTemplate = ({ content, contentComponent, description, title, helmet }) => {
  const PostContent = contentComponent || Content;

  return (
    <Box>
      {helmet || ""}
      <Container>
        <Box py="3rem">
          <Heading as="h1" size="xl" pb="4">
            {title}
          </Heading>
          <Text pb="4">{description}</Text>
          <PostContent className="content" content={content} />
        </Box>
      </Container>
    </Box>
  );
};

CoursePostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const CoursePost = ({ data, ...props }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout {...props}>
      <CoursePostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet>
            <title>{`${post.frontmatter.title}`}</title>
            <meta name="description" content={`${post.frontmatter.description}`} />
          </Helmet>
        }
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

CoursePost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default CoursePost;

export const pageQuery = graphql`
  query CoursePostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
      }
    }
  }
`;
